var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { MAINTAIN_PERMISSION, FILTERS_STARTS_WITH, STERILIZATION_SPECIFICATIONS_REPORTS } from "@kraftheinz/common";
import queryString from "query-string";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "reports" }, [_c("a-row", [_c("a-col", { attrs: { "sm": 24, "md": 16 } }, [_c("a-card", { staticClass: "search-area" }, [_c("label", { attrs: { "disabled": _vm.showSearchLoader } }, [_vm._v("Please search for sterilisation")]), _c("a-row", { staticClass: "mt-3", attrs: { "gutter": 8 } }, [_c("a-col", { attrs: { "span": 24 } }, [_c("a-input-group", { attrs: { "compact": "" } }, [_c("a-select", { staticClass: "w-25", attrs: { "default-value": "ItemCode", "disabled": _vm.showSearchLoader }, on: { "change": function(val) {
    _vm.setValue(val, "selectedType");
    _vm.clear();
  } } }, [_c("a-select-option", { attrs: { "value": "ItemCode" } }, [_vm._v("Item Code")]), _c("a-select-option", { attrs: { "value": "FGItemCode" } }, [_vm._v("FG Item Code")]), _c("a-select-option", { attrs: { "value": "SterilizationCode" } }, [_vm._v("Sterilisation Code")])], 1), _c("a-auto-complete", { staticClass: "w-75", attrs: { "disabled": _vm.showSearchLoader, "option-label-prop": "selectedItemCode", "placeholder": "Please enter code" }, on: { "select": _vm.onSelect, "search": _vm.setFilterParams, "change": _vm.onChange }, model: { value: _vm.selectedItemCode, callback: function($$v) {
    _vm.selectedItemCode = $$v;
  }, expression: "selectedItemCode" } }, [_c("template", { slot: "dataSource" }, [_vm.dataSource.length > 0 ? _c("a-select-option", { key: "label" }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v("Item Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("Proc Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("Fg Item Code")]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v("Description")])])]) : _vm._e(), _vm._l(_vm.dataSource, function(opt) {
    return _c("a-select-option", { key: opt.id, attrs: { "value": JSON.stringify(opt) } }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v(_vm._s(opt.itemCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(opt.procCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(opt.fgItemCode))]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v(_vm._s(opt.description))])])]);
  }), _vm.dataSource.length > 0 && !_vm.isComplete ? _c("a-select-option", { key: "infinite" }, [_c("infinite-loading", { on: { "infinite": _vm.onInfinite } })], 1) : _vm._e()], 2)], 2)], 1)], 1)], 1)], 1)], 1), _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    var reset = ref.reset;
    return [_c("a-col", { attrs: { "sm": 24, "md": 16 } }, [_c("div", { staticClass: "mt-4 mb-3 h6" }, [_vm._v("Result")]), _c("a-card", { staticClass: "results" }, [_c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "sm": 24, "md": 6 } }, [_c("label", [_vm._v("Item Code")]), _c("a-input", { attrs: { "value": _vm.searchResults.itemCode ? _vm.searchResults.itemCode : "", "disabled": "" } })], 1), _c("a-col", { attrs: { "sm": 24, "md": 18 } }, [_c("label", [_vm._v("Description")]), _c("a-input", { attrs: { "value": _vm.searchResults.itemDescription ? _vm.searchResults.itemDescription : "", "disabled": "" } })], 1)], 1), _c("div", { staticClass: "mt-5 mb-2" }, [_vm._v(" Please select properties below to Preview or Print ")]), _c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { attrs: { "span": 6 } }, [_c("label", [_vm._v("Method")]), _c("validation-provider", { attrs: { "name": "Method", "rules": "required" }, scopedSlots: _vm._u([{ key: "default", fn: function(slotProps) {
      return [_c("a-form-item", { attrs: { "help": slotProps.errors[0], "validate-status": _vm.resolveState(slotProps) } }, [_c("a-select", { staticStyle: { "width": "100%" }, attrs: { "disabled": !(_vm.searchResults && _vm.searchResults.options && _vm.searchResults.options.length > 0), "value": _vm.selectedMethod.methodName }, on: { "change": _vm.onMethodSelection }, model: { value: _vm.selectedMethodName, callback: function($$v) {
        _vm.selectedMethodName = $$v;
      }, expression: "selectedMethodName" } }, _vm._l(_vm.searchResults.options, function(opt) {
        return _c("a-select-option", { key: opt.methodId, attrs: { "value": JSON.stringify(opt) } }, [_vm._v(_vm._s(opt.methodName))]);
      }), 1)], 1)];
    } }], null, true) })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("label", [_vm._v("Chain")]), _c("a-form-item", [_c("a-select", { staticStyle: { "width": "100%" }, attrs: { "disabled": !_vm.showChain, "value": _vm.selectionObj.chain }, on: { "change": function(val) {
      return _vm.setValue(val, "chain");
    } } }, [_c("a-select-option", { attrs: { "value": "I" } }, [_vm._v("Inner")]), _c("a-select-option", { attrs: { "value": "O" } }, [_vm._v("Outer")])], 1)], 1)], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("label", [_vm._v("Cooker")]), _c("validation-provider", { attrs: { "name": "Cooker", "rules": _vm.showCooker ? "required" : void 0 }, scopedSlots: _vm._u([{ key: "default", fn: function(slotProps) {
      return [_c("a-form-item", { attrs: { "help": slotProps.errors[0], "validate-status": _vm.resolveState(slotProps) } }, [_c("a-select", { staticStyle: { "width": "100%" }, attrs: { "disabled": !_vm.showCooker, "value": _vm.selectionObj.cooker, "loading": _vm.isMethodLoading }, on: { "change": function(val) {
        return _vm.setValue(val, "cooker");
      } } }, _vm._l(_vm.searchResults.cookers, function(opt) {
        return _c("a-select-option", { key: opt.methodId, attrs: { "value": opt.cooker } }, [_vm._v(_vm._s(opt.cooker))]);
      }), 1)], 1)];
    } }], null, true) })], 1), _c("a-col", { attrs: { "span": 6 } }, [_c("label", [_vm._v("Issue Date")]), _c("a-form-item", [_c("a-date-picker", { attrs: { "format": "DD-MM-YYYY", "disabled": !_vm.selectionObj.method, "value": _vm.selectionObj.issueDate }, on: { "change": function(val) {
      return _vm.setValue(val, "issueDate");
    } } })], 1)], 1)], 1), _c("div", { staticClass: "d-flex justify-content-end border-top mt-3 pt-3" }, [_c("a-button", { staticClass: "ml-3", attrs: { "disabled": _vm.showSearchLoader, "type": "default" }, on: { "click": function($event) {
      return _vm.clear(reset);
    } } }, [_vm._v("Clear All")]), _c("a-button", { staticClass: "ml-3", attrs: { "disabled": _vm.showSearchLoader, "loading": _vm.showPreviewLoader, "type": "primary" }, on: { "click": function($event) {
      return handleSubmit(_vm.submit);
    } } }, [_vm._v("Preview")])], 1)], 1)], 1)];
  } }]) })], 1)], 1);
};
var staticRenderFns$1 = [];
var Reports_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script$1 = {
  name: "Reports",
  inject: ["apiUrl", "crud"],
  data() {
    return {
      selectionObj: {
        selectedType: "ItemCode",
        selectedCode: null,
        method: "",
        chain: "",
        cooker: "",
        issueDate: null
      },
      searchResults: {},
      dataSource: [],
      selectedItemCode: null,
      searchLoading: false,
      selectedMethod: {},
      selectedMethodName: "",
      previewLoading: false,
      permissions: {
        maintain: MAINTAIN_PERMISSION
      },
      reports: {
        CC: "ContinuousCooker",
        PHY: "PetfoodHydrostaticCooker",
        HY: "HydrostaticCooker",
        ST: "SteriflowRetort",
        RR: "RRetort",
        ROR: "SRetort",
        SRS: "SRetort",
        SRR: "SRetort",
        PA: "Pasteuriser",
        PST: "PetRetort",
        KST: "SteriflowKingstreetRetort",
        LST: "SteriflowLagardeRetort",
        OR: "ORetort",
        PSS: "PetfoodSteriTech"
      },
      isComplete: true,
      isMethodLoading: false
    };
  },
  computed: {
    cookers() {
      return this.searchResults.cookers || [];
    },
    showChain() {
      return this.selectionObj.method === "HY";
    },
    showCooker() {
      return this.selectionObj.method === "PHY" || this.selectionObj.method === "CC" || this.selectionObj.method === "HY";
    },
    showSearchLoader() {
      return this.searchLoading;
    },
    showPreviewLoader() {
      return this.previewLoading;
    }
  },
  watch: {
    cookers() {
      this.selectionObj.cooker = void 0;
    },
    async searchResults(newVal) {
      if (Object.keys(newVal).length > 0) {
        let obj = {};
        Object.keys(newVal.options[0]).forEach((key) => {
          Object.assign(obj, { [key]: newVal.options[0][key] });
        });
        await this.onMethodSelection(JSON.stringify(obj));
        this.setValue(newVal.cookers[0].cooker, "cooker");
      }
    }
  },
  mounted() {
    this.crud.deleteFilter("IsInactive");
  },
  methods: {
    setValue(val, key) {
      let value = val;
      if (key == "selectedType") {
        this.clear();
        value = val;
      }
      if (key == "method") {
        this.selectedMethod = this.searchResults.options.find((item) => item.methodId === val);
        value = this.selectedMethod.method;
      }
      this.selectionObj = __spreadProps(__spreadValues({}, this.selectionObj), { [key]: value });
    },
    async onMethodSelection(val) {
      try {
        let methodObj = JSON.parse(val);
        this.selectedMethodName = methodObj.methodName;
        this.setValue(this.$moment(methodObj.issueDate), "issueDate");
        this.setValue(methodObj.methodId, "method");
        if (this.selectionObj.selectedType !== "SterilizationCode") {
          this.isMethodLoading = true;
          await this.search(this.selectionObj.selectedCode, methodObj.procCode);
        }
        const values = {
          HY: {
            type: "chain",
            value: "I"
          },
          CC: {
            type: "cooker",
            value: this.searchResults.cookers[0] && this.searchResults.cookers[0].cooker
          }
        };
        if (values[this.selectedMethod.method]) {
          this.selectionObj[values[this.selectedMethod.method].type] = values[this.selectedMethod.method].value;
          if (this.selectedMethod.method !== "HY") {
            this.selectionObj["chain"] = "";
            this.selectionObj = __spreadValues({}, this.selectionObj);
          }
        }
      } catch (e) {
        this.$notification.error({
          message: "Errors encountered. Please try again!"
        });
      }
    },
    async search(selectedCode, selectedMethod) {
      if (!this.selectionObj.selectedCode)
        return;
      this.searchLoading = true;
      const queries = this.selectionObj.selectedType !== "SterilizationCode" ? queryString.stringify({
        itemCode: selectedCode,
        procCode: selectedMethod
      }) : queryString.stringify({
        procCode: selectedCode
      });
      try {
        const res = await this.axios.get(`${this.apiUrl}/sterilization/reports/report-options?${queries}`);
        if (res.data) {
          if (selectedMethod && selectedCode) {
            this.searchResults.cookers = res.data.cookers;
          } else
            this.searchResults = res.data;
          this.isMethodLoading = false;
        }
      } catch {
        this.searchResults = {};
      } finally {
        this.searchLoading = false;
        this.isMethodLoading = false;
      }
    },
    async getSuggestionList() {
      const { pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: 1, pageSize });
      const res = await this.crud.fetchList(this.apiUrl + `/sterilization/common/item-masters`);
      if (res.data) {
        this.dataSource = res.data.map((item, idx) => {
          return Object.assign({}, item, { id: idx + "" });
        });
      }
      this.isComplete = false;
    },
    setFilterParams(searchText) {
      if (!searchText)
        return;
      this.crud.setFilter(this.selectionObj.selectedType, {
        operator: FILTERS_STARTS_WITH,
        value: searchText
      });
      this.crud.getFilterQuery();
      this.getSuggestionList();
    },
    onSelect(selectedItemObj) {
      this.clearData();
      try {
        this.selectedItemCode = this.selectionObj.selectedType !== "SterilizationCode" ? JSON.parse(selectedItemObj).itemCode : JSON.parse(selectedItemObj).procCode;
        this.setValue(this.selectedItemCode, "selectedCode");
        this.crud.setFilter(this.selectionObj.selectedType, this.selectedItemCode);
        this.search(this.selectionObj.selectedCode);
      } catch (e) {
        this.$notification["error"]({
          message: "Errors encountered. Please try again!"
        });
      }
    },
    clearData() {
      this.selectionObj = Object.assign({}, {
        selectedType: this.selectionObj.selectedType,
        selectedCode: this.selectionObj.selectedCode
      }, {
        method: "",
        chain: "",
        cooker: "",
        issueDate: null
      });
      this.searchResults = {};
      this.selectedMethod = {};
      this.selectedMethodName = "";
    },
    clear(reset) {
      this.crud.deleteFilter(this.selectionObj.selectedType);
      this.dataSource = [];
      this.selectedItemCode = "";
      this.isComplete = false;
      this.clearData();
      if (reset)
        reset();
    },
    onChange(e) {
      this.selectionObj.selectedCode = e;
    },
    submit() {
      const data = {
        report: this.reports[this.selectedMethod.method],
        reportType: "steri",
        reportParams: {
          BSItemCode: this.searchResults.itemCode,
          Chain: this.selectionObj.chain,
          Cooker: this.selectionObj.cooker,
          IssueDate: this.$moment(this.selectedMethod.issueDate).format("YYYY-MM-DD"),
          Method: this.selectedMethod.method,
          ProcCode: this.selectedMethod.procCode,
          ProcHeadId: this.selectedMethod.procHeadId
        }
      };
      this.previewLoading = true;
      this.axios.post(`${this.apiUrl}/identities/reports`, data).then((res) => {
        if (!res || !res.data || !res.data.reportViewerUri) {
          this.$notification["error"]({
            message: "Report not found!"
          });
        } else {
          window.open(res.data.reportViewerUri, "_blank");
        }
      }, (err) => {
        this.$notification["error"]({
          message: this._.get(err, "response.data.message") || "Errors!"
        });
      }).finally(() => this.previewLoading = false);
    },
    resolveState({ errors }) {
      if (errors[0])
        return "error";
      return "";
    },
    async onInfinite($state) {
      const { page, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: page + 1, pageSize });
      const res = await this.crud.fetchList(this.apiUrl + `/sterilization/common/item-masters`, false);
      if (res.data.length) {
        $state.loaded();
      } else {
        $state.complete();
        this.isComplete = true;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "68c2edd7", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Reports = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("div", { staticClass: "neutral-1 px-5 pb-3 h5" }, [_vm._v("Sterilisation")]), _c("resource", { attrs: { "name": "sterilization.reports", "api-url": _vm.apiUrl, "resource-id-name": "itemCode", "redirect-route": "/sterilization-specifications/data-entries", "page": _vm.page } }, [_c("reports")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    Reports
  },
  data() {
    return {
      page: STERILIZATION_SPECIFICATIONS_REPORTS,
      apiUrl: "#{VUE_APP_API_URL}#",
      itemsMenu: [
        {
          key: "reports",
          title: "Reports",
          path: "/sterilization-specifications/reports"
        },
        {
          key: "Sterilisation",
          title: "Sterilisation",
          path: "/sterilization-specifications/reports/sterilisation"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
